// @ts-nocheck
export default function debounce<T>(func: (props: T) => void, wait: number, immediate: boolean = false) {
  let timeout;
  return function() {
  	let context = this, args = arguments;
  	clearTimeout(timeout);
  	if (immediate && !timeout) func.apply(context, args);
  	timeout = setTimeout(function() {
  		timeout = null;
  		if (!immediate) func.apply(context, args);
  	}, wait);
  };
}
